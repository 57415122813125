#abilitiesInner{
	padding: 2em 0;
}

#abilitiesInner h2{
	/* color: #6b3a83; */
	margin: 0;
	text-align: center;
	letter-spacing: .05em;
	font-size: 3.2em;
	font-weight: bold;
	padding-top: .5em;
	background-color: #fff;

}

#abilitiesInner h4{
	padding: 0.5em;
	text-align: center;
	/* color: #294593; */
	letter-spacing: .05em;
	font-weight: bold;
	font-size: 2.2em;

}

#abilitiesInner p{
	text-align: center;
	font-size: 1.1em;
}
