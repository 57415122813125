.App {
  height: 100%;
  width: 100%;
}

.container-fluid{
  padding: 0;
}


.row{
  margin: 0;
}

.loader{
  margin: 15em auto;
}
