#footer{
	text-align: center;
	background: #000;
	color: #fff;
	padding: 3em 0;
}

#bottominfo ul{
	padding: 0;
	font-size: 1.8em;
}

#footerNav{
	margin-bottom: 1em;
}

#footer h2{
	/* color: #1eb473; */
	padding-top: 1em;
	text-align: center;
	letter-spacing: .1em;
	font-size: 6em;
	font-weight: bold;
}

#footer h4{
	/* color: #e6e6e5; */
	padding: 1em;
}

#footer h6{
	margin-bottom: 0;
}

#footer ul li{
	display: inline-block;
}

#footer a{
	color: #e6e6e5;
	font-size: 1em;

}

#footer a:hover{
	color: #294593;
}

#footer a:visited{
	color: #1eb473;
}

.copy{
	font-size: 0.8em;
	margin-bottom: 0;
}

@media (max-width: 786px){
	#footer{
		padding: 3em 1em;
	}
}
