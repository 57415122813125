.galleryImage{
  width: 100%;
  height: 325px;
  border-radius: 12px;
  padding: 0;
  margin: 1em 0;
  box-shadow: 5px 3px 6px rgba(0,0,0,0.1);
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: background-image 750ms;
  transition: background-image 750ms;
  overflow: hidden;
}

.infoBackground{
  color: #fff;
  background-color: rgba(0,0,0,0);
  position: relative;
  width: 90%;
  margin: 0 auto;
  /* padding-top: 25em; */
  text-align: center;
  transition: padding 750ms;
}

.infoBackground p{
  margin-bottom: 1em;
  font-size: 1.25em;
}

.infoBackground a {
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
}

@media (max-width: 1024px){
  .galleryImage{
    height: 175px;
    margin: 0.55em;
  }

  .infoBackground h3{
    font-size: 1.22em;
  }

  .infoBackground p{
    margin-bottom: 0;
    font-size: 0.8em;
  }

  .infoBackground a {
    font-size: 1em;
  }

}

@media (max-width: 768px){
  .galleryImage{
    height: 300px;
    margin: 0.5em 0;
    background-position: top;
  }

  .infoBackground h3{
    font-size: 2em;
  }

  .infoBackground p{
    font-size: 1.5em;
  }

  .infoBackground a {
    font-size: 1.5em;
  }

}

@media (max-width: 600px){
  .galleryImage{
    height: 250px;
  }

  .infoBackground h3{
    font-size: 1.4em;
  }

  .infoBackground p{
    font-size: 1em;
  }

  .infoBackground a {
    font-size: 1em;
  }

}
