@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
#snake {
    text-transform: uppercase;
    font-family: 'Press Start 2P', cursive;
}

table {
    text-align: center;
    margin: 1rem auto;
}

table th {
    min-width: 7rem;
}