header {
    text-align: center;
    background-image: url(https://d1s0e8co9abnql.cloudfront.net/background.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: 0 -165px; */
    height: 65vh;
    color: #fff;
}

#homeInner {
    padding-top: 34vh;
}

#homeInner h3 {
    font-size: 2em;
}

#homeInner h1 {
    font-size: 3.2em;
}

#homeInner p {
    font-size: 1.5em;
    padding: 0 0.5em;
}

@media (max-width: 1024px) {
    #homeInner p {
        font-size: 1.5em;
        padding: 0 6em;
    }
}

@media (max-width: 768px) {
    header {
        height: 60vh;
    }
    #homeInner {
        padding-top: 26vh;
    }
}

@media (max-width: 600px) {
    #homeInner h3 {
        font-size: 1.2em;
    }
    #homeInner h1 {
        font-size: 1.5em;
    }
    #homeInner p {
        font-size: 1em;
        padding: 0 4em;
    }
}